import React from "react";
import { Field, Input, Label, Select } from "@headlessui/react";

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  children: React.ReactNode;
};

export function LabelField({ label }: { label: string }) {
  return <Label className="text-gray-400 text-xs">{label}</Label>;
}

export function InputField({ label, ...props }: InputProps) {
  return (
    <Field>
      <LabelField label={label} />
      <Input
        {...props}
        className="w-full border border-gray-300 text-xs p-3 rounded-lg"
      />
    </Field>
  );
}

export function SelectField({ children, label, ...props }: SelectProps) {
  return (
    <Field>
      <LabelField label={label} />
      <Select
        {...props}
        className="w-full border border-gray-300 text-xs p-3 rounded-lg appearance-none"
      >
        {children}
      </Select>
    </Field>
  );
}
